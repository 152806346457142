import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import Divider from '../components/Divider';

const NotFoundPage = () => (
  <Layout>
    <div className="error-page-container">
      <Row style={{ marginTop: '60px', marginBottom: '60px' }}>
        <Col xs="1" md="2" />
        <Col xs="10" sm="10" md="6">
          <h1 className="h1-blue center-mobile">Error 404.</h1>
          <Divider width="100%" height="40px" />
          <h1 className="h1-blue center-mobile">
            We could not find the data you are looking for.
          </h1>
          <Divider width="100%" height="40px" />
          <Link
            to="/"
            className="btn btn-outline-primary center-mobile button-apercu"
          >
            GO BACK HOME
          </Link>
        </Col>
        <Col />
      </Row>
    </div>
  </Layout>
);

export default NotFoundPage;
